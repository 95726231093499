<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
const enableAnimations = defineModel<boolean>({ required: true });

const emit = defineEmits<{ start: [] }>();

const { isLoggedIn, isGhostStudent } = useAuth();

const showLoginButton = computed(() => {
  return !isLoggedIn.value || isGhostStudent.value;
});
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-12" data-testid="onboarding-landed">
    <div class="relative">
      <UIHeading :htmlText="$t('activities.onboarding.selfDiscovery')" class="text-center text-white" size="h1" styleSize="h2" />
      <div v-if="!isLoggedIn" class="absolute p-1 text-sm font-semibold bg-teal-500 rounded w-fit -top-4 -right-8">FREE</div>
    </div>
    <p class="text-sm text-center text-white" v-html="$t('activities.onboarding.onboardingIntro')" />
    <UISwitch v-model="enableAnimations" :labelLeft="$t('general.animationSwitch.off')" :labelRight="$t('general.animationSwitch.on')" theme="dark" />
    <div class="flex gap-2 items-center text-white max-w-[300px]">
      <UIAssetIcon path="alert" size="xl" />
      <p class="text-sm w-fit">{{ $t('activities.nonRefreshWarning') }}</p>
    </div>
    <slot name="cta">
      <UIButton :text="$t('activities.onboarding.startYourJourney')" data-testid="next-action" @click="emit('start')" />
      <UIButton
        v-if="showLoginButton"
        :text="$t('activities.onboarding.alreadyHaveAnAccount')"
        class="text-white underline"
        to="/login"
        variant="no-style"
      />
    </slot>
  </div>
</template>
